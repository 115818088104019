var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ErrorBoundary',[_c('div',{staticClass:"relative flex flex-col min-h-screen lg:pl-56 xl:pl-64"},[(!_vm.currentUser)?_c('AnnularThrobber',{staticClass:"m-auto"}):[_c('GlobalDelinquentBanner'),_vm._v(" "),_c('TierUpgradeNoticeModal'),_vm._v(" "),_c('PausedAccountBanner'),_vm._v(" "),_c('CreditCardFailedModal',{attrs:{"title":"Unable to complete purchase"}}),_vm._v(" "),(_vm.shouldShowBanner)?_c('TierUpgradeNoticeBanner',{on:{"open-modal":_vm.openModal}}):_c('div',[(_vm.shouldShowServiceUpdate && _vm.isBannerVisible)?_c('div',[_c('ServiceUpdateBanner'),_vm._v(" "),_c('ServiceUpdateModal')],1):_vm._e()]),_vm._v(" "),_c('TheHeader',{staticClass:"sticky top-0 z-30 lg:relative",attrs:{"is-nav-open":_vm.isNavOpen},on:{"toggle-nav":function($event){_vm.isNavOpen = !_vm.isNavOpen}}}),_vm._v(" "),_c('TheNav',{staticClass:"fixed inset-y-0 left-0 z-40 w-64 lg:w-56 xl:w-64",attrs:{"is-nav-open":_vm.isNavOpen}}),_vm._v(" "),_c('div',[_c('main',{staticClass:"flex flex-col flex-grow",class:[
              _vm.fullBleed
                ? 'max-w-full'
                : 'px-4 pb-8 lg:px-6 main-content h-full' ],attrs:{"id":"main-content"}},[(_vm.showCountdown)?_c('CountdownBanner',{staticClass:"m-4"}):_vm._e(),_vm._v(" "),_c('nuxt',{staticClass:"flex-grow",class:{ 'pt-6': !_vm.fullBleed }}),_vm._v(" "),_c('NavOverlay',{attrs:{"is-nav-open":_vm.isNavOpen}})],1),_vm._v(" "),_c('portal-target',{attrs:{"name":"default-layout-footer"}})],1)]],2),_vm._v(" "),_c('portal-target',{attrs:{"name":"tour-trigger"}}),_vm._v(" "),(_vm.scheduler.show)?_c('CalendlyModal',{attrs:{"event-path":_vm.calendlyEventPath,"guest-name":_vm.currentUser.profile.full_name,"guest-email":_vm.currentUser._auth.email,"answers":_vm.eventAnswers,"utm-content":_vm.eventUtmContent,"utm-medium":_vm.eventUtmMedium},on:{"event-scheduled":_vm.onCallScheduled,"close":_vm.resetScheduler}}):_vm._e(),_vm._v(" "),_c('CoreTOS',{attrs:{"close-on-submit":true,"show-modal":_vm.showTermsOfService,"show-close":false},on:{"hide-modal":_vm.handleCoreTOS}}),_vm._v(" "),(_vm.showLongStandingDelinquent)?_c('LongStandingDelinquentModal',{on:{"close":function($event){_vm.showLongStandingDelinquent = false}}}):_vm._e(),_vm._v(" "),(_vm.showAdBlockerModal)?_c('AdBlockerModal',{on:{"close":function($event){_vm.showAdBlockerModal = false}}}):_vm._e(),_vm._v(" "),_c('portal-target',{staticClass:"relative z-40",attrs:{"name":"drawer-portal"}}),_vm._v(" "),_c('portal-target',{staticClass:"relative z-50 layout-portal",attrs:{"name":"layout"}}),_vm._v(" "),(
        !_vm.showTermsOfService &&
        _vm.userSavings.doesCustomerQualify &&
        _vm.showInAppUpgrade
      )?_c('IntervalUpgradeOffer',{attrs:{"user-savings":_vm.userSavings}}):_vm._e(),_vm._v(" "),(_vm.isRootModalOpen)?_c('RootModal'):_vm._e(),_vm._v(" "),_c('EscalationsPopup'),_vm._v(" "),_c('BaseNotifications'),_vm._v(" "),(!_vm.showTermsOfService)?_c('CookiePolicyBanner',{staticClass:"fixed left-0 right-0 bottom-0 z-50"}):_vm._e(),_vm._v(" "),(_vm.shouldShowUpgradeModal)?_c('PlanSelectorModal',{attrs:{"recommended-package":_vm.recommendedPackage},on:{"close":function($event){return _vm.toggleUpgradeModal(false)}}}):_vm._e(),_vm._v(" "),(_vm.showSupportModal)?_c('SupportModal',{attrs:{"action-options":_vm.supportOptions.action_options,"contact-options":_vm.supportOptions.contact_options},on:{"close":_vm.closeSupportModal}}):_vm._e(),_vm._v(" "),(_vm.shouldShowReactivateModal)?_c('ReactivatePlanModal',{on:{"close":function($event){return _vm.toggleReactivateModal(false)}}}):_vm._e(),_vm._v(" "),(_vm.isPayrollRootModalOpen)?_c('PayrollRootModal'):_vm._e(),_vm._v(" "),(_vm.showHRMSupport)?_c('ModalDialog',{attrs:{"size":"large","overlay":"dark"},on:{"close":function($event){_vm.showHRMSupport = !_vm.showHRMSupport}}},[_c('CalendlyCard',{attrs:{"url":_vm.currentCalendlyUrl},on:{"event-scheduled":function($event){return _vm.handleCallScheduled($event)}}})],1):_vm._e(),_vm._v(" "),(_vm.showAIChat)?_c('ConversationModal',{on:{"close":_vm.closeAIChat}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }