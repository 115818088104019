import CachePolicy from '@/gql/CachePolicy';
import useNotifications from '@bambeehr/use-notifications';
import useErrorHandler from '@/hooks/useErrorHandler';
import publicClient from '@/gql/apolloClient';
import {
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
} from '@nuxtjs/composition-api';
import {
  MutateResult,
  UseMutationOptions,
  UseMutationReturn,
  UseQueryOptions,
  UseQueryReturn,
  provideApolloClient,
} from '@vue/apollo-composable';

export interface ResponseError {
  error: Error;
  raw: Object;
}

export interface WrapperError {
  networkError: Error | null;
  responseErrors: ResponseError[];
  hasErrors: boolean;
}

export interface Placeholders {
  // Placeholders can be 'any' as they are set by the consuming component on the ref
  // This allows the consumer to use placeholderPick and set the expected sub type.
  data: Ref<any>;
  errors: Ref<ResponseError[]>;
  pending: Ref<boolean>;
}

export interface WrapperOptions {
  handleErrors?: boolean;
  placeholderPick?: Function;
  force?: boolean;
}

const wrapperOptionDefaults = {
  handleErrors: true,
  placeholderPick: (val) => val,
  force: false,
};

const { addError } = useNotifications();
export const apolloClient = ref();

function setClient() {
  provideApolloClient(publicClient);
}

export const logError = (input: {
  error: Error;
  operation: string;
  message: string;
  surfacedToUser: boolean;
  args?: any;
}) => {
  const additionalInfo = {
    surfacedToUser: input.surfacedToUser,
    operation: input.operation,
    message: input.message,
    args: input.args,
  };

  window?.DD_RUM?.addError(input.error, additionalInfo);
};

/**
 * Surfaces errors to users when they are not included in a list of error matches */
export function surfaceErrorToUser(errorMessage: string) {
  // Define rules
  const isAccessControlError = errorMessage.match(/access token/i);
  const isAuthHeader = errorMessage.match(/authorization/i);
  const unexpectedToken = errorMessage.match(/unexpected token/i);
  const networkError = errorMessage.includes('Network Error');
  const pollenComponentError = errorMessage.includes(
    "Cannot read properties of undefined (reading '$el')"
  );

  const blacklisted = [
    isAccessControlError,
    isAuthHeader,
    unexpectedToken,
    networkError,
    pollenComponentError,
  ];

  // all error matches should be falsy in order for us to surface to a user
  const shouldSurfaceToUser = blacklisted.every((matched) => !matched);

  return shouldSurfaceToUser;
}

export function handleErrors<T>(
  errData: WrapperError,
  placeholders: Partial<Placeholders>,
  options: WrapperOptions,
  operation: string,
  args?: any
) {
  if (placeholders?.errors) {
    placeholders.errors.value = errData.responseErrors;
  }

  if (options.handleErrors && errData?.hasErrors) {
    if (errData?.networkError) {
      const { handle } = useErrorHandler();
      const message = errData.networkError?.message;

      const surfacedToUser = surfaceErrorToUser(message);
      if (surfacedToUser) {
        handle(new Error(message));
      }
      logError({
        error: errData.networkError,
        operation,
        message,
        surfacedToUser,
        args,
      });

      return;
    }

    errData.responseErrors.forEach(({ error }) => {
      const message = error?.message;

      const surfacedToUser = surfaceErrorToUser(message);
      if (surfacedToUser) {
        addError(message);
      }
      logError({
        error,
        operation,
        message,
        surfacedToUser,
      });
    });
  }
}

export const formatErrors = (
  networkError: Error | null,
  responseErrors: ResponseError[]
) => ({
  networkError,
  responseErrors,
  hasErrors: !!networkError || !!responseErrors?.length,
});
// @ts-ignore, Ignoring type extension error for onDone
export interface WrapMutationReturn<MResult, MVariables>
  extends Omit<UseMutationReturn<MResult, MVariables>, 'error' | 'onError'> {
  onError: (fn: (err: WrapperError) => any) => any;
  onDone: (fn: (param: MResult) => any) => any;
  errors: ComputedRef<WrapperError>;
}

declare type MutationFn<MResult, MVariables> = (
  options: UseMutationOptions<MResult, MVariables>
) => UseMutationReturn<MResult, MVariables>;

export function useApolloMutation<MResult, MVariables>(
  mutation: MutationFn<MResult, MVariables>,
  placeholders: Partial<Placeholders> = {},
  wrapperOptions: WrapperOptions = {},
  apolloOptions: UseMutationOptions<MResult, MVariables> = {}
): WrapMutationReturn<MResult, MVariables> {
  const mergedWrapperOptions = {
    ...wrapperOptionDefaults,
    ...wrapperOptions,
  };
  const {
    mutate: apolloMutate,
    onDone: onMutationDone,
    loading,
    error: networkError,
    ...rest
  } = mutation({
    ...apolloOptions,
  });

  const responseErrors = ref<ResponseError[]>([]);
  const responseData = ref();
  const onDoneFn = ref<Function>();

  const mutate = (...args): MutateResult<MResult> => {
    // Since mutate can be called at a later time, we need to set the client
    // right before the mutation is called so the correct client is used.
    setClient();

    return apolloMutate(...args);
  };

  onMutationDone((res) => {
    if (res.errors) {
      responseErrors.value = res.errors.map((e) => ({
        error: new Error(e.message),
        raw: e,
      }));
    }
    if (res.data) {
      responseData.value = res.data;

      if (placeholders?.data) {
        placeholders.data.value = mergedWrapperOptions.placeholderPick(
          res.data
        );
      }

      onDoneFn.value?.(res.data);
    }
  });

  const onDone = (fn: (param: MResult) => void) => {
    onDoneFn.value = fn;
  };

  const errors: ComputedRef<WrapperError> = computed(() =>
    formatErrors(networkError.value, responseErrors.value)
  );

  const onError = (fn: (err: WrapperError) => void): void => {
    // Check if user has explicitly set this option
    // If they haven't (undefined) we'll automatically disable the auto handling
    // of errors if they invoke the onError event hook.
    if (wrapperOptions.handleErrors === undefined) {
      mergedWrapperOptions.handleErrors = false;
    }

    watch(errors, (errData: WrapperError) => {
      fn(errData);
    });
  };

  watch(
    loading,
    (isLoading: boolean): void => {
      if (placeholders.pending) {
        placeholders.pending.value = isLoading;
      }
    },
    { immediate: true }
  );

  watch(
    errors,
    (errData: WrapperError): void => {
      handleErrors<MResult>(
        errData,
        placeholders,
        mergedWrapperOptions,
        `${mutation}`
      );
    },
    { immediate: true }
  );

  return {
    ...rest,
    onDone,
    errors,
    onError,
    loading,
    mutate,
  };
}

export interface WrapQueryReturn<QResult, QVariables>
  extends Omit<
    UseQueryReturn<QResult, QVariables>,
    'error' | 'onResult' | 'onError'
  > {
  onError: (fn: (err: WrapperError) => any) => any;
  onResult: (fn: (param: QResult) => any) => any;
  errors: ComputedRef<WrapperError>;
}

declare type QueryFn<QResult, QVariables> = (
  variables: QVariables,
  options: UseQueryOptions<QResult, QVariables>
) => UseQueryReturn<QResult, QVariables>;

declare type QueryFnNoVar<QResult, QVariables> = (
  options: UseQueryOptions<QResult, QVariables>
) => UseQueryReturn<QResult, QVariables>;

export function useApolloQuery<QResult, QVariables>(
  query: QueryFn<QResult, QVariables> | QueryFnNoVar<QResult, QVariables>,
  data: QVariables,
  placeholders: Partial<Placeholders> = {},
  wrapperOptions: WrapperOptions = {},
  apolloOptions: UseQueryOptions<QResult, QVariables> = {}
): WrapQueryReturn<QResult, QVariables> {
  setClient();

  const mergedWrapperOptions = {
    ...wrapperOptionDefaults,
    ...wrapperOptions,
  };
  const fetchPolicyOverride = mergedWrapperOptions.force
    ? { fetchPolicy: CachePolicy.NETWORK_ONLY }
    : {};

  const queryOptions = {
    ...apolloOptions,
    ...fetchPolicyOverride,
  };

  const {
    result: queryResult,
    loading,
    error: networkError,
    onResult: onQueryResult,
    ...rest
  } = data
    ? (query as QueryFn<QResult, QVariables>)(data, queryOptions)
    : (query as QueryFnNoVar<QResult, QVariables>)(queryOptions);

  const responseErrors = ref<ResponseError[]>([]);
  const result = ref();

  onQueryResult((res) => {
    if (res.errors) {
      responseErrors.value = res.errors.map((e) => ({
        error: new Error(e.message),
        raw: e,
      }));
    }

    if (res.data && placeholders?.data) {
      placeholders.data.value = mergedWrapperOptions.placeholderPick(res.data);
    }
  });

  if (placeholders?.data && queryResult.value) {
    placeholders.data.value = mergedWrapperOptions.placeholderPick(
      queryResult.value
    );
  }

  watch(
    queryResult,
    (res) => {
      result.value = res || null;
    },
    {
      immediate: true,
      deep: true,
    }
  );

  const onResult = (fn: (param: QResult) => void): any => {
    watch(
      result,
      (res) => {
        if (res) {
          fn(res);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  };

  const errors: ComputedRef<WrapperError> = computed(() =>
    formatErrors(networkError.value, responseErrors.value)
  );

  const onError = (fn: (err: WrapperError) => void): void => {
    // Check if user has explicitly set this option
    // If they haven't (undefined) we'll automatically disable the auto handling
    // of errors if they invoke the onError event hook.
    if (wrapperOptions.handleErrors === undefined) {
      mergedWrapperOptions.handleErrors = false;
    }
    watch(errors, (errData: WrapperError) => {
      fn(errData);
    });
  };

  watch(
    loading,
    (isLoading: boolean): void => {
      if (placeholders.pending) {
        placeholders.pending.value = isLoading;
      }
    },
    { immediate: true }
  );

  watch(
    errors,
    (errData: WrapperError): void => {
      handleErrors<QResult>(
        errData,
        placeholders,
        mergedWrapperOptions,
        `${query}`,
        data
      );
    },
    { immediate: true }
  );

  return {
    ...rest,
    loading,
    errors,
    onError,
    onResult,
    result,
  };
}
