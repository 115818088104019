function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"h-full overflow-y-auto transition-transform transform bg-white duration-light lg:transform-none border-r border-solid border-base-300",class:{
    '-translate-x-full': !_vm.isNavOpen,
    'translate-x-0': _vm.isNavOpen,
  }},[_c('div',{staticClass:"flex flex-col max-w-full min-h-full p-2"},[_c('div',{staticClass:"relative flex items-center gap-3 p-2"},[_c('BambeeLogo',{staticClass:"flex-shrink-0"}),_vm._v(" "),_c('CompanySelect',{staticClass:"flex-grow",attrs:{"active":_vm.companyId,"companies":_vm.allCompanies,"light":false},on:{"input":_vm.handleCompanyChange}})],1),_vm._v(" "),_c('ul',{staticClass:"flex flex-col gap-1"},_vm._l((_vm.navItems),function(ref){
        var label = ref.label;
        var icon = ref.icon;
        var to = ref.to;
        var handler = ref.handler;
        var tag = ref.tag;
        var key = ref.key;
        var tagColor = ref.tagColor; if ( tagColor === void 0 ) tagColor = 'caribbean';
        var preventDelinquent = ref.preventDelinquent;
        var activeSubLabel = ref.activeSubLabel;
        var isActive = ref.isActive;
        var rest$1 = objectWithoutProperties( ref, ["label", "icon", "to", "handler", "tag", "key", "tagColor", "preventDelinquent", "activeSubLabel", "isActive"] );
        var rest = rest$1;
return _c('li',_vm._b({key:key || icon,staticClass:"flex -mx-2"},'li',rest,false),[_c('NavItem',{staticClass:"flex-grow",attrs:{"handler":handler,"icon":icon,"to":to,"locked":preventDelinquent &&
            _vm.lockLongStandingDelinquent &&
            _vm.isLongStandingDelinquent,"locked-tooltip":"Update your payment to gain access"}},[_c('div',[_c('div',[_vm._v("\n              "+_vm._s(label)+"\n            ")]),_vm._v(" "),(activeSubLabel && isActive)?_c('div',{staticClass:"font-normal text-base-500 text-11"},[_vm._v("\n              "+_vm._s(activeSubLabel)+"\n            ")]):_vm._e()]),_vm._v(" "),(_vm.incompleteTasksCount && key === _vm.NavItemKeys.DASHBOARD)?_c('CounterBadge',{staticClass:"ml-auto",attrs:{"value":_vm.incompleteTasksCount,"variant":"secondary"}}):_vm._e(),_vm._v(" "),(tag)?_c('BaseTag',{staticClass:"ml-auto",attrs:{"color":tagColor,"size":"small"}},[_vm._v("\n            "+_vm._s(tag)+"\n          ")]):_vm._e()],1)],1)}),0),_vm._v(" "),_c('div',{staticClass:"mt-auto"},[(_vm.isHrRequestInLeftNav)?[_c('AdvisorCard',{staticClass:"border-t border-primary-light"}),_vm._v(" "),_c('RedesignModal')]:_c('UserSubNav',{staticClass:"-mx-2"})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }