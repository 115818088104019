<template>
  <component
    :is="to ? 'NuxtLink' : 'button'"
    v-tooltip="tooltipData"
    class="nav-item flex p-2 pl-5 cursor-pointer text-base-700 box-border border-l-4 border-transparent items-center"
    :class="{ 'cursor-not-allowed': locked, elite: isElite }"
    :active-class="to ? 'nav-item--selected' : null"
    :to="to"
    @click="!locked && handler()"
  >
    <div
      class="flex flex-shrink-0 h-4 items-center justify-center mr-4 mt-px text-16 w-4"
    >
      <BaseIcon :icon="finalIcon" :class="classes" />
    </div>
    <TypeBody
      class="nav-item__label flex flex-grow items-center"
      :class="classes"
      tag="div"
      variant="link-x-small-tight"
      weight="strong"
    >
      <slot>
        {{ label }}
      </slot>
    </TypeBody>
  </component>
</template>

<script>
import { BaseIcon, TypeBody } from '@bambeehr/pollen';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo/useCompanyBillingInfo';
import { computed } from '@nuxtjs/composition-api';

export default {
  components: {
    BaseIcon,
    TypeBody,
  },
  props: {
    activeIcon: {
      type: String,
      required: false,
    },
    handler: {
      type: Function,
      default: () => {},
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: null,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    lockedTooltip: {
      type: String,
      default: '',
    },
    lockedIcon: {
      type: String,
      default: 'lock',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isElite } = useCompanyBillingInfo();

    return {
      isElite,
    };
  },
  computed: {
    finalIcon() {
      return this.locked ? this.lockedIcon : this.icon;
    },
    tooltipData() {
      return (
        this.locked && {
          content: this.lockedTooltip,
        }
      );
    },
  },
};
</script>

<style scoped>
.nav-item.nav-item--selected {
  @apply border-primary-default  font-bold text-primary-strong bg-primary-weak !important;
}

.nav-item:hover {
  @apply border-primary-lighter  !important;
}

.nav-item.elite.nav-item--selected {
  @apply border-elite text-primary-strong font-bold bg-tuberose !important;
}

.nav-item.elite:hover {
  @apply border-elite !important;
}
</style>
