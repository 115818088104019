































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { BaseLink, BaseButton, BaseIcon, ModalDialog } from '@bambeehr/pollen';
import ContentfulRichText from '@/components/contentful/ContentfulRichText.vue';
import { fetchContent } from '@/modules/OnboardingWizard/utils/copy';

export default defineComponent({
  name: 'CompanyInfo',
  components: {
    ModalDialog,
    BaseIcon,
    BaseButton,
    ContentfulRichText,
    BaseLink,
  },
  props: {
    // To be used with the contentful entry type: Explainer Tooltip
    // https://app.contentful.com/spaces/tb3mx4vb7wqk/content_types/explainerTooltip/fields
    entryId: {
      type: String as PropType<string>,
      required: true,
    },
    modalSize: {
      type: String as PropType<string>,
      default: 'large',
    },
    icon: {
      type: String as PropType<string>,
      default: 'infoCircleSolid',
    },
    showModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    btnLabel: {
      type: String as PropType<string>,
      default: 'Okay, got it!',
    },
  },
  setup(props) {
    const isVisible = ref(props.showModal);
    const linkText = ref();
    const content = ref();
    // Give the PM the ability to show/hide from contentful
    const hideTooltip = ref();

    watch(props, ({ showModal: newVal }) => {
      isVisible.value = newVal;
    });

    const toggleModal = () => {
      isVisible.value = !isVisible.value;
    };

    (async () => {
      const res = await fetchContent(props.entryId);

      content.value = res?.modalContent;
      linkText.value = res?.tooltipLauncherText;
      hideTooltip.value = !res?.isVisible;
    })();

    return {
      isVisible,
      toggleModal,
      content,
      linkText,
      hideTooltip,
    };
  },
});
