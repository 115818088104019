export enum WizardEvents {
  ENTERED = 'ENTERED',
  EXITED = 'EXITED',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'COMPLETED',
  HELP_CLICKED = 'HELP_CLICKED',
}

export enum AIChatModalEvents {
  OPEN = 'AI_CHAT_OPEN',
  CLOSE = 'AI_CHAT_CLOSE',
}

export enum AIConversationEvents {
  MESSAGE_RECEIVED = 'AI_CONVERSATION_MESSAGE_RECEIVED',
  MESSAGE_SENT = 'AI_CONVERSATION_MESSAGE_SENT',
  CONVERSATION_ENDED = 'AI_CONVERSATION_ENDED',
}

export default WizardEvents;
