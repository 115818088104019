const FeatureFlags = Object.freeze({
  DISPLAY_HR_REQUESTS_AS_GRID: 'display-hr-requests-as-grid',
  DISPLAY_HR_REQUESTS_IN_LEFT_NAV: 'display-hr-requests-in-left-nav',
  ENABLE_HR_COMPLIANCE: 'hr-compliance',
  ENABLE_NEW_SERVICE_CLOUD_REQUESTS: 'enable-new-service-cloud-requests',
  ENABLE_PAYROLL: 'enable-payroll',
  ENABLE_PAYROLL_IMPORT_HOURS: 'enable-payroll-import-hours',
  ENABLE_STAFF_MESSAGING: 'staff-messaging',
  ENABLE_BULK_STAFF_MESSAGING: 'bulk-staff-messaging',
  ENABLE_TRAINING: 'enable-training',
  ENABLE_CONTACT_US_ROUTING: 'contact-us-routing',
  ENABLE_NECTAR: 'enable-nectar',
  LOCK_LONG_STANDING_DELINQUENTS: 'lock-long-standing-delinquents',
  TERMINATION_FLOW_2022: 'termination-flow-2022',
  TRAINING_CATALOG: 'training-catalog',
  AUTO_UPGRADE_TIER: 'auto-upgrade-tier',
  END_EMPLOYMENT_WIZARD_2022: 'end-employment-wizard-2022',
  SHOW_ADD_STAFF_WIZARD: 'show-add-staff-wizard',
  ENABLE_NECTAR_UPGRADE_NOTIFICATION: 'enable-nectar-upgrade-notification',
  ENABLE_BCI_TO_HR_COMPLIANCE: 'Hr-Practices-Fall-2022',
  ENABLE_POLICY_REAFFIRMATIONS: 'enable-policy-reaffirmations',
  ENABLE_RE_AUDIT: 'enable-re-audit',
  ENABLE_TEAM_PAGE: 'enable-team-page',
  ENABLE_ESCALATIONS: 'enable-escalations',
  ENABLE_CREATE_JOB_DESCRIPTIONS: 'enable-create-job-descriptions',
  ENABLE_MANAGED_SERVICES_MARKETING: 'enable-managed-services-marketing',
  AI_POLICY_REQUEST: 'ai-policy-request',
  CONTENT_BANNERS: {
    ER_PAYROLL_OVERVIEW: 'content-banner-er-payroll-overview',
    EE_PAYROLL_PAYSTUBS: 'content-banner-ee-payroll-paystubs',
  },
  ENABLE_POLICY_ARCHIVING: 'enable-policy-archiving',
  BAMBEE_PLUS_MARKETING_BANNER: 'bambee-plus-marketing-banner',
  W2_PREVIEW_REPORT: 'w-2-preview-report',
  EXCLUDE_CASH_TIPS_FROM_OT_BASE_RATE: 'exclude-cash-tips-from-ot-base-rate',
  VIEW_CONVERSATION_ON_MY_REQUESTS: 'view-conversation-on-my-requests',
  OFFER_NEW_PAYROLL_PLANS: 'offer-new-payroll-plans-q-2-fy-24',
  MEETINGS_FEATURE: 'feature-meetings',
  CASE_DETAILS_REVAMP: 'case-details-revamp',
});

export default FeatureFlags;
