import useCurrentUser from '@/hooks/useCurrentUser';
import { computed } from '@nuxtjs/composition-api';

const { currentUser } = useCurrentUser();

const company = computed(() => ({
  id: currentUser.value?._company._id || '',
  name: currentUser.value?._company.name,
  dba: (currentUser.value?._company?.profile?.dba || '').trim(),
  plan: currentUser.value?._company.plan,
  owner: currentUser.value?._company._owner,
  profile: currentUser.value?._company.profile,
  status: currentUser.value?._company.status || '',
}));

const companyId = computed(() => company.value?.id);

const isTrial = computed(() => {
  return company.value?.status?.toLowerCase() === 'trial';
});

const companySettings = computed(() => {
  const settings = currentUser.value?._company?.settings || {
    manager_can_grade_employees: false,
    showReportCardFeedback: false,
    show_report_card: false,
    bciToHRPracticeMigrated: false, // Toggle for BCI customers who converted to HR Practice
    bciToHRPracticeConvertedAt: null,
  };

  const {
    manager_can_grade_employees: managerCanGradeEmployees,
    showReportCardFeedback,
    show_report_card: showReportCard,
    bciToHRPracticeMigrated,
    bciToHRPracticeConvertedAt,
  } = settings;

  return {
    bciToHRPracticeConvertedAt,
    bciToHRPracticeMigrated,
    managerCanGradeEmployees,
    showReportCard,
    showReportCardFeedback,
  };
});

const useCurrentCompany = () => {
  return {
    //
    company,
    companyId,
    companySettings,
    isTrial,
  };
};

export default useCurrentCompany;
