const { constants } = require('@bambeehr/pollen');

const { inset, spacing, transitionDuration, borderRadius, ...rest } =
  constants.theme.default;

// Exclude some styles from purging
const hrScoreColors = ['error', 'warning', 'gingerrr', 'success', 'mosaic'];
const safelistHrScore = hrScoreColors.flatMap((color) => [
  `border-${color}`,
  `text-${color}`,
]);

// List of custom colors to safelist
const customColors = [
  'lite',
  'elite',
  'dedicated',
  'payrollBasic',
  'payrollPremium',
];

// Safelist color utilities
const safelistColors = customColors.flatMap((color) => [
  `bg-${color}`,
  `border-${color}`,
  `text-${color}`,
]);

module.exports = {
  important: true,
  theme: {
    ...rest,
    extend: {
      inset,
      borderWidth: {
        '.5': '2px',
      },
      spacing: {
        ...spacing,
        18: '4.5rem',
      },
      fontFamily: {
        poppins: "'Poppins','sans serif'",
      },
      borderRadius,
      transitionDuration,
      minHeight: (theme) => ({
        ...theme('spacing'),
      }),
      maxWidth: {
        xxs: '19rem',
      },
      colors: {
        lite: '#B44EDA',
        elite: '#dbb275',
        dedicated: '#4E1976',
        payrollBasic: '#008685',
        payrollPremium: '#dbb275',
      },
    },
  },
  variants: {
    extend: {
      translate: ['group-hover'],
    },
  },
  plugins: [],
  purge: {
    // Learn more on https://tailwindcss.com/docs/controlling-file-size/#removing-unused-css
    enabled: process.env.NODE_ENV === 'production',
    content: [
      './src/modules/**/*.vue',
      './src/components/**/*.vue',
      './src/layouts/**/*.vue',
      './src/modules/**/*.vue',
      './src/pages/**/*.vue',
      './src/plugins/**/*.js',
    ],
    safelist: [
      // Dynamically assigned / HR Score
      ...safelistHrScore,
      // Custom colors
      ...safelistColors,
    ],
  },
};
