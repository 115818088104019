import useCurrentUser from '@/hooks/useCurrentUser';
import CookieNames from '@/constants/Cookies';
import { getCookie } from '@/lib/cookie-helpers';
import { jwtExpired } from './axios';
import { useAdvisorsStore } from '@/store/advisors';
import useCompanyStore from '@/store/companies';

const StoreActions = Object.freeze({
  FETCH_EMPLOYEES: 'users/getSimplifiedMyEmployees',
  FETCH_POLICIES: 'policies/getMyPoliciesSimplified',
  FETCH_MILESTONES: 'milestones/getMilestones',
  FETCH_FEED: 'feeds/getFeeds',

  FETCH_NOTIFICATIONS: 'notifications/fetchNotifications',
  FETCH_TICKETS: 'tickets/getAll',
  FETCH_HR_EXPECTATIONS: 'getHRExpectations',
  FETCH_INSURANCE_PLANS: 'insurance/getPlans',
  FETCH_PRICE_PLANS: 'pricePlans/fetch',
});

export default async function ({ app, route, store }) {
  const { token } = route.query;
  const { setCurrentUser } = useCurrentUser();

  store.subscribe((mutation) => {
    if (['setCurrentCompany', 'setCurrentUser'].includes(mutation.type)) {
      const { currentUser } = store.getters;
      setCurrentUser(currentUser);
      if (window?.DD_RUM) {
        const userAttrs = {
          userId: currentUser?._id,
          name: currentUser.profile?.full_name,
          email: currentUser._auth.email,
          roles: [currentUser.role],
          // @ts-ignore
          companyId: currentUser._company?.id,
          companyName: currentUser._company?.name,
          planId: currentUser._company?.plan,
          isSingleClient: true,
          marketing: currentUser._company?.marketing,
          states: currentUser._company?.states,
          trial: currentUser._company?.trial,
        };

        window.DD_RUM.setUser(userAttrs);
        window.DD_RUM.setGlobalContextProperty('user', userAttrs);

        if (window?.DD_RUM) {
          window.DD_RUM.addAction('single-client');
        }
      }
    }
  });

  try {
    await store.dispatch('notifications/fetchHolidays');
  } catch (err) {
    console.error(err);
  }

  let selfUrl = `/v0/app/my/self`;
  if (token && token.length) {
    if (!jwtExpired(token)) {
      selfUrl = `/v0/app/my/self?token=${token}`;
    }
  } else {
    const accessToken = getCookie(CookieNames.ACCESS_TOKEN);
    if (!accessToken) {
      return false;
    }
  }

  return app.$axios
    .get(selfUrl)
    .then(async (res) => {
      const user = res.data;

      store.commit('setCurrentUser', user);

      const { getAdvisors } = useAdvisorsStore();

      // ClientInit Fetches
      await store.dispatch(StoreActions.FETCH_NOTIFICATIONS);
      await store.dispatch(StoreActions.FETCH_TICKETS);
      await useCompanyStore().getMyCompanies();
      await store.dispatch(StoreActions.FETCH_HR_EXPECTATIONS);
      await store.dispatch(StoreActions.FETCH_INSURANCE_PLANS);
      await store.dispatch(StoreActions.FETCH_PRICE_PLANS);
      await useCompanyStore().getOnboardingMilestones();
      await getAdvisors(store.getters.currentUser?._company._id);
    })
    .catch((err) => {
      console.error(err);
    });
}
